import * as React from "react";
import styled from "styled-components";

import { Box } from "@quandoo-dev/cookbook/lib/layout";
import { Body } from "@quandoo-dev/cookbook/lib/typography";
import { themeGet as $ } from "@styled-system/theme-get";
import { order, OrderProps, flex, FlexProps } from "styled-system";

import Link from "../link";
import Search from "../search";

const List = styled.ul<OrderProps>`
  display: flex;
  overflow: scroll;
  max-width: 106%;
  list-style: none;
  padding: 0 ${$("space.lg")};
  margin-left: -${$("space.lg")};
  margin-right: -${$("space.lg")};
  ${order};
  ${flex};

  @media screen and (min-width: ${$("breakpoints.lg")}) {
    margin-left: 0;
    margin-right: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; 
    scrollbar-width: none;
`;

const Item = styled(Box)`
  height: 40px;
  border-radius: 48px;
`;

export interface ILink {
  name: string;
  url: string;
}

type NavigationProps = { localeSlug: string } & OrderProps &
  FlexProps & { links: ILink[] };

const Navigation: React.FC<NavigationProps> = ({ localeSlug, links, ...props }) => {
  return (
    
    <List {...props}>
      <Item
        key="search"
        order={{ _: 0, lg: 1 }}
        ml={{ _: "0", lg: "auto" }}
        mr={{ _: "xs", lg: "md" }}
      >
        <Search localeSlug={localeSlug} />
      </Item>
      {links.map(({ name, url }, i) => (
        <Item
          key={`${url}-${i}`}
          bg={{ _: "background.light", lg: "transparent" }}
          px="md"
          py="xs"
          mr={["xs", "xs", "md"]}
        >
          <Link to={url}>
            <Body color="default" fontWeight="semibold">
              {name}
            </Body>
          </Link>
        </Item>
      ))}
    </List>
  );
};

export default Navigation;
