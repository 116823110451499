import * as React from "react";
import { Flex, Box } from "@quandoo-dev/cookbook/lib/layout";
import { Heading } from "@quandoo-dev/cookbook/lib/typography";

import FacebookLogo from "../../assets/facebook.inline.svg";
import InstagramLogo from "../../assets/instagram.inline.svg";

interface SocialLink {
  url: string;
  name: string;
  socialNetwork: string;
}

interface SocialBlockProps {
  links: SocialLink[];
  heading?: string;
}

const SOCIAL_NETWORK_LOGO_MAPPING = {
  facebook: <FacebookLogo />,
  instagram: <InstagramLogo />,
};

const SocialBlock: React.FC<SocialBlockProps> = ({ heading, links }) => {
  return (
    <>
      {heading && (
        <Heading size={6} mb="md">
          {heading}
        </Heading>
      )}
      <Flex>
        {links.map(({ name, url, socialNetwork }) => (
          <Box mr="xs" key={name}>
            <a href={url} target="_blank">
              {SOCIAL_NETWORK_LOGO_MAPPING[socialNetwork]}
            </a>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default SocialBlock;
