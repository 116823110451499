import * as React from "react";
import { Heading } from "@quandoo-dev/cookbook/lib/typography";

import GooglePlayLogo from "../../assets/googleplay.png";
import AppStoreLogo from "../../assets/appstore.png";
import { Box } from "@quandoo-dev/cookbook/lib/layout";

const AppsBlock: React.FC<{}> = () => {
  return (
    <>
      <Heading size={6} mb="md">
        Apps
      </Heading>
      <Box as="img" width="135px" src={GooglePlayLogo} />
      <Box as="img" width="136px" src={AppStoreLogo} />
    </>
  );
};

export default AppsBlock;
