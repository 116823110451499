import * as React from "react";
import { Box, Flex as _Flex } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";
import { themeGet as $ } from "@styled-system/theme-get";

import SearchIcon from "./assets/search-icon.inline.svg";

import useTranslation from "../../hooks/useTranslation";

const Flex = styled(_Flex)`
  fill: ${$("colors.text.secondary")};
`;

/**
 * For now just a mock of Search UI.
 * Later should be done as a form element
 * with input
 */

interface SearchProps {
  localeSlug: string;
}

const Search: React.FC<SearchProps> = ({ localeSlug }) => {
  return (
    <Flex
      color="red"
      height="40px"
      borderRadius="48px"
      bg="background.light"
      p={{ _: "sm", lg: "md" }}
      alignItems="center"
    >
      <SearchIcon />
      <Box display={{ _: "none", lg: "block" }}>
        <form method="GET" action={`/`} noValidate>
          <input
            type="text"
            name="s"
            placeholder={useTranslation(localeSlug, "header.search")}
            style={{
              border: "0",
              backgroundColor: "#F0F2F5",
              outline: "0",
              textIndent: "10px",
            }}
          ></input>
        </form>
      </Box>
    </Flex>
  );
};

export default Search;
