import * as React from "react";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import styled from "styled-components";
import { themeGet as $ } from "@styled-system/theme-get";

import Link from "../link";

interface Link {
  url: string;
  name: string;
}

interface LinksProps {
  links: Link[];
  heading?: string;
}

const LinksList = styled.ul`
  padding: 0;
  margin-bottom: 0;
  list-style: none;

  li {
    margin-top: ${$("space.xxs")};

    :first-child {
      margin-top: 0;
    }
  }
`;

const Links: React.FC<LinksProps> = ({ heading, links }) => {
  return (
    <>
      {heading && <Heading size={6}>{heading}</Heading>}
      <LinksList>
        {links.map(({ name, url }) => (
          <li key={name}>
            <a href={url} style={{textDecoration: "none"}}>
              <Body size="sm">{name}</Body>
            </a>
          </li>
        ))}
      </LinksList>
    </>
  );
};

export default Links;
