import * as React from "react";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import styled from "styled-components";
import { themeGet as $ } from "@styled-system/theme-get";
import { flexDirection, FlexDirectionProps } from "styled-system";

import Link from "../link";

interface Link {
  url: string;
  name: string;
}

interface LinksProps {
  links: Link[];
}

const LinksList = styled.ul<FlexDirectionProps>`
  display: flex;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  ${flexDirection};

  li {
    margin-top: ${$("space.xxs")};

    :first-child {
      margin-top: 0;
    }

    @media screen and (min-width: ${$("breakpoints.2")}) {
      margin-top: 0;
      margin-right: ${$("space.lg")};
    }
  }
`;

const Links: React.FC<LinksProps> = ({ links }) => {
  return (
    <LinksList flexDirection={["column", "column", "column", "row"]}>
      {links.map(({ name, url }) => (
        <li key={name}>
          {name == "Imprint" ?
            <a href={url} style={{ textDecoration: "none" }}>
              <Body size="sm">{name}</Body>
            </a> :
            <Link to={url}>
              <Body size="sm">{name}</Body>
            </Link>}

        </li>
      ))}
    </LinksList>
  );
};

export default Links;
