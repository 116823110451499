import { useStaticQuery, graphql } from "gatsby";

const resourcesQuery = graphql`
  query {
    allContentfulResourceSet {
      edges {
        node {
          title
          locale {
            slug
          }
          resources {
            key
            value {
              value
            }
          }
        }
      }
    }
  }
`;

function useTranslation(country: string, key: string): string | null {
  const {
    allContentfulResourceSet: { edges: resourceSet },
  } = useStaticQuery(resourcesQuery);

  const resources = resourceSet.filter(resource =>
    resource.node.locale.some(locale => locale.slug == country)
  )[0].node.resources;

  const translation = resources.find(r => r.key === key);

  return translation?.value?.value ?? null;
}

export default useTranslation;
