import * as React from "react";
import { ThemeProvider } from "styled-components";
import theme from "@quandoo-dev/cookbook/lib/themes/b2b";
import CookbookGlobalStyle, { GlobalStyles } from "./global";
import { loadCookbookFonts } from "@quandoo-dev/cookbook/lib/global";

import Header from "../header";
import Footer from "../footer";
import { Box } from "@quandoo-dev/cookbook/lib/layout";

const breakpoints = ["sm", "md", "lg", "xl"];
breakpoints.forEach((breakpoint, i) => {
  theme.breakpoints[breakpoint] = theme.breakpoints[i];
});

loadCookbookFonts();

interface LayoutProps {
  hideHeader?: boolean;
  locale?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, locale }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CookbookGlobalStyle />
        <GlobalStyles />
        <div>
          <Header locale={locale} />
          <Box
            as="main"
            mx={{ _: "lg", lg: "auto" }}
            maxWidth={{ _: "auto", lg: "928px", xl: "1220px" }}
          >
            {children}
          </Box>
          <Footer locale={locale} />
        </div>
      </>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  hideHeader: false,
  locale: "uk",
};

export default Layout;
