import * as React from "react";

import Dropdown from '@quandoo-dev/cookbook/lib/dropdown-menu';
import styled from "styled-components";
import { Box as _Box, Flex } from "@quandoo-dev/cookbook/lib/layout";
import { position, verticalAlign } from "styled-system";
import Link from "../link";

import Icon from "../../assets/localisation.inline.svg";
import { Body } from "@quandoo-dev/cookbook/lib/typography";
import Arrow from '../../assets/arrow-down-drop.inline.svg'

interface DropdownProps {
  countryLinks: any;
  country: any;
}

const Box = styled(_Box)`
  ${verticalAlign};
  ${position}
`;

const DropdownMenuList = styled(Dropdown.MenuList)`
  [data-reach-menu-list] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const Menu: React.FC<DropdownProps> = ({countryLinks, country}) => {
 
  return (
    <Dropdown>
      <Dropdown.Trigger>
        <Flex
          border="thin"
          borderRadius="md"
          borderColor="border.default"
          bg="background.default"
          py="sm"
          alignItems="center"
          minWidth="285px"
          px="md"
        >
          <Box
            display="inline"
            mr="md"
            position="relative"
            top="1px"
            verticalAlign="middle"
          >
            <Icon width="14" height="14" />
          </Box>
          <Body>{country}</Body>
          <Arrow style={{marginLeft: "auto"}} />
        </Flex>
      </Dropdown.Trigger>
      <Dropdown.MenuList>
        {countryLinks.map(({ name, url }) => (
            <Dropdown.Link key={name} href={url}>{name}</Dropdown.Link>
        ))}
      </Dropdown.MenuList>
    </Dropdown>
  );
};

export default Menu;
