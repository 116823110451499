import { createCookbookGlobalStyle } from "@quandoo-dev/cookbook/lib/global";

import avertaRegularWoff from "@quandoo-dev/cookbook/assets/fonts/averta-regular.woff";
import avertaRegularItalicWoff from "@quandoo-dev/cookbook/assets/fonts/averta-regularitalic.woff";
import avertaBoldWoff from "@quandoo-dev/cookbook/assets/fonts/averta-bold.woff";
import avertaSemiBold from "@quandoo-dev/cookbook/assets/fonts/averta-semibold.woff";
import avertaExtraBoldWoff from "@quandoo-dev/cookbook/assets/fonts/averta-extrabold.woff";
import { createGlobalStyle } from "styled-components";
import { themeGet as $ } from "@styled-system/theme-get";

const CookbookGlobalStyle = createCookbookGlobalStyle({
  normal: avertaRegularWoff,
  normalItalic: avertaRegularItalicWoff,
  bold: avertaBoldWoff,
  semiBold: avertaSemiBold,
  extraBold: avertaExtraBoldWoff,
});

const GlobalStyles = createGlobalStyle`
  body {
    color: ${$("colors.text.default")};
  }
`;

export default CookbookGlobalStyle;
export { GlobalStyles };
