import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Box, Flex } from "@quandoo-dev/cookbook/lib/layout";
import { Body } from "@quandoo-dev/cookbook/lib/typography";

import FooterBlock from "./footer-block";
import Links from "./links";
import LegalLinks from "./legal-links";
import Dropdown from "../dropdown";
import SocialBlock from "./social-block";
import AppsBlock from "./apps-block";
import Newsletter from "./newsletter";

interface footerProps {
  locale: string;
}

const Footer: React.FC<footerProps> = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFooter {
        edges {
          node {
            name
            locale {
              slug
            }
            logo {
              title
              fixed(width: 117) {
                width
                height
                src
                srcSet
              }
            }
            linkBlocks {
              heading
              isSocialBlock
              links {
                __typename
                ... on ContentfulLink {
                  name
                  url
                }
                ... on ContentfulSocialLink {
                  name
                  url
                  socialNetwork
                }
              }
            }
            legalLinks {
              name
              url
            }
            countryLinks {
              name
              url
            }
          }
        }
      }
    }
  `);
  const contentfulFooter = data.allContentfulFooter.edges.find(
    (footer: { node: { locale: { slug: any } } }) =>
      footer.node.locale.slug === locale
  ).node;

  const {
    logo: { title, fixed },
    name,
    linkBlocks,
    legalLinks,
    countryLinks,
  } = contentfulFooter;

  return (
    <Box bg="background.lightest" pt="xxl" pb="lg" px="lg">
      <Box
        maxWidth={["375px", "375px", "375px", "928px", "1216px"]}
        m={["0", "0", "0", "auto"]}
      >
        <Img alt={title} fixed={fixed} />

        <Flex flexWrap={["wrap", "wrap", "wrap", "nowrap"]}>
          <FooterBlock mt="xl">
            <Box mb="md" mr="xl">
              <Dropdown countryLinks={countryLinks} country={name} />
            </Box>
          </FooterBlock>
          {linkBlocks.map(({ heading, links, isSocialBlock }) => (
            <FooterBlock key={heading}>
              {isSocialBlock === true ? (
                <SocialBlock heading={heading} links={links} />
              ) : (
                  <Links heading={heading} links={links} />
                )}
            </FooterBlock>
          ))}
          <FooterBlock>
            <AppsBlock />
          </FooterBlock>
        </Flex>
        {/* <Newsletter /> */}
        <Flex
          flexDirection={["column", "column", "column", "row"]}
          alignItems={["flex-start", "flex-start", "flex-start", "flex-end"]}
          justifyContent="space-between"
        >
          <Box mt="xxl">
            <LegalLinks links={legalLinks} />
          </Box>
          <Body mt="xxl">Copyright &copy; {new Date().getFullYear()}</Body>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
