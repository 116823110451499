import * as React from "react";

import Navigation, { ILink } from "../navigation";
import { Flex, Box } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";
import Img, { GatsbyImageProps } from "gatsby-image";

import Button from "@quandoo-dev/cookbook/lib/button";
import { Link as _Link, useStaticQuery, graphql } from "gatsby";
import { order, OrderProps } from "styled-system";
import useTranslation from "../../hooks/useTranslation";

type ImageLogoProps = GatsbyImageProps & OrderProps;

const ImageLogo = styled(Img)<ImageLogoProps>`
  ${order};
`;

type LinkProps = OrderProps;

const Link = styled.a<LinkProps>`
  ${order}
`;

interface headerProps {
  locale: string;
}

const Header: React.FC<headerProps> = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHeader {
        edges {
          node {
            locale {
              slug
            }
            slug
            logo {
              title
              fixed(width: 117) {
                width
                height
                src
                srcSet
              }
            }
            ctaLink
            navigationLinks {
              name
              url
            }
          }
        }
      }
    }
  `);
  const contentfulHeader = data.allContentfulHeader.edges.find(
    (header: { node: { locale: { slug: string } } }) =>
      header.node.locale.slug === locale
  ).node;

  const {
    ctaLink,
    logo: { title, fixed },
    navigationLinks,
    locale: {slug: localeSlug}
  } = contentfulHeader;

  return (
    <Box mx="lg" pt="sm">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexWrap={["wrap", "wrap", "wrap"]}
      >
        <Link href={`/`}>
          <ImageLogo order={{ _: 1 }} fixed={fixed} alt={title} />
        </Link>
        <Navigation
          links={navigationLinks as ILink[]}
          order={{ _: 3, lg: 2 }}
          flex={{ _: "1 1 100%", lg: "1 1 0" }}
          localeSlug={localeSlug}
          style={{overflow: "auto"}}
        />
        <Link href={ctaLink} order={{ _: 2, lg: 3 }}>
          <Button as="button" variation="secondary">
          {useTranslation(localeSlug, "header.cta")}
          </Button>
        </Link>
      </Flex>
    </Box>
  );
};

export const query = graphql`
  fragment headerData on ContentfulHeader {
    slug
  }
`;

export default Header;
