import * as React from "react";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import { SpaceProps } from "styled-system";

type BlockProps = SpaceProps;

const Block: React.FC<BlockProps> = ({ children, ...spaceOverrides }) => {
  return (
    <Box
      flex={["1 1 50%", "1 1 50%", "1 1 50%", "1 1 20%"]}
      mt="xxl"
      {...spaceOverrides}
    >
      {children}
    </Box>
  );
};

export default Block;
